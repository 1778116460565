<template>
<section class="skewed-top-left skewed-bottom-right">
  <div class="py-20 bg-gray-50 radius-for-skewed">
    <div class="container mx-auto px-4">
      <div class="max-w-xl mx-auto text-center">
        <span class="mb-6 inline-block p-3 bg-green-200 rounded">
          <svg class="w-6 h-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"></path>
          </svg>
        </span>
        <h2 class="mb-4 text-4xl md:text-5xl font-bold font-heading">Change E-Mail</h2>
        <p class="mb-6 text-gray-500">Change the e-mail address below to initate the process to change your e-mail address</p>
        <div class="flex flex-wrap justify-center items-center">
          <input v-model="newEmailAddress" :readonly="verifyButtonClicked" class="w-full md:w-auto mb-3 md:mb-0 md:mr-4 py-2 px-4 bg-white rounded leading-loose" type="email" placeholder="hello@example.com">
          <input v-model="verificationCode" :readonly="updateButtonClicked" v-if="verifyButtonClicked" class="w-full md:w-auto mb-3 md:mb-0 md:mr-4 py-2 px-4 bg-white rounded leading-loose" type="text" placeholder="Verification Code">
          <button v-if="newEmailAddress != oldEmailAddress && !verifyButtonClicked" @click="verifyEmail()" class="w-full md:w-auto py-2 px-6 bg-green-600 hover:bg-green-700 text-white font-bold leading-loose rounded-l-xl rounded-t-xl transition duration-200">Verify</button>
          <button v-else-if="newEmailAddress != oldEmailAddress" @click="updateEmail()" class="w-full md:w-auto py-2 px-6 bg-green-600 hover:bg-green-700 text-white font-bold leading-loose rounded-l-xl rounded-t-xl transition duration-200">Update</button>
        </div>
      </div>
    </div>
  </div>
</section>

  <div class="skew skew-top mr-for-radius">
    <svg class="h-8 md:h-12 lg:h-20 w-full text-gray-50" viewBox="0 0 10 10" preserveAspectRatio="none">
      <polygon fill="currentColor" points="0 0 10 10 0 10"></polygon>
    </svg>
  </div>

<section class="skewed-top-left skewed-bottom-right">
  <div class="py-20 bg-gray-50 radius-for-skewed">
    <div class="container mx-auto px-4">
      <div class="max-w-xl mx-auto text-center">
        <span class="mb-6 inline-block p-3 bg-green-200 rounded">
          <svg class="w-6 h-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"></path>
          </svg>
        </span>
        <h2 class="mb-4 text-4xl md:text-5xl font-bold font-heading">Change Password</h2>
        <p class="mb-6 text-gray-500">Enter your old and new password below, then click on "Change"</p>
        <div class="flex flex-wrap justify-center items-center">
          <input v-model="oldPassword" class="w-full md:w-auto mb-3 md:mb-0 md:mr-4 py-2 px-4 bg-white rounded leading-loose" type="password" placeholder="Old Password">
          <input v-model="newPassword" class="w-full md:w-auto mb-3 md:mb-0 md:mr-4 py-2 px-4 bg-white rounded leading-loose" type="password" placeholder="New Password">
          <button @click="changePassword()" class="w-full md:w-auto py-2 px-6 bg-green-600 hover:bg-green-700 text-white font-bold leading-loose rounded-l-xl rounded-t-xl transition duration-200">Change</button>
        </div>
      </div>
    </div>
  </div>
</section>

<vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content" :esc-to-close="true" :lock-scroll="false">
  <div class="py-20 bg-gray-50 radius-for-skewed">
    <div class="container mx-auto px-4">
      <div class="mx-auto max-w-md text-center">
        <span class="text-green-600 font-bold">Verification Code Sent</span>
        <h2 class="text-4xl lg:text-5xl font-bold font-heading">Check your E-Mail</h2>
        <p class="mt-10">A Verification Code has been sent to your new e-mail address. Click anywhere to close this Window and enter the code in the verification code field</p>
      </div>
    </div>
  </div>
</vue-final-modal>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Profile',
    data () {
    return {
      showModal: false,
      verifyButtonClicked: false,
      updateButtonClicked: false,
      oldEmailAddress: 'leon@kura.space',
      newEmailAddress: 'leon@kura.space',
      verificationCode: null,
      oldPassword: null,
      newPassword: null,
    }
  },
  methods: {
    async verifyEmail() {
      this.showModal = true
      this.verifyButtonClicked = true
      console.log(`Verify E-Mail ${this.newEmailAddress}`)
    },
    async updateEmail() {
      this.updateButtonClicked = true
      console.log(`Updating E-Mail ${this.newEmailAddress} with Verification Code ${this.verificationCode}`)
      this.verifyButtonClicked = false
      this.updateButtonClicked = false
      this.oldEmailAddress = this.newEmailAddress
      this.verificationCode = null
    },
    async changePassword() {
      const response = await axios.patch(`${process.env.VUE_APP_BACKEND_URL}/users/60e83c60f24b4f8c861570be/password`, {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      })

      if (response.status === 200) {
      this.oldPassword = null
      this.newPassword = null
      } else {
        console.log('An Error Occured')
      }

    }
  },
}
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__title {
  font-size: 1.5rem;
  font-weight: 700;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>